import { User } from "./user";

export enum PaymentTypes {
  card = "card",
  paypal = "paypal",
  applePay = "applePay",
  googlePay = "googlePay",
}

export enum ProductType {
  written = "written",
  audio = "audio",
  video = "video",
  shortCall = "shortCall",
  longCall = "longCall",
  free = "free",
}

export enum ProductTitleByType {
  default = "Feedback",
  written = "Written Feedback",
  audio = "Audio Feedback",
  video = "Video Feedback",
  shortCall = "Short Call",
  longCall = "Long Call",
  free = "Free Submission",
}

export enum ProductNameByType {
  written = "Written",
  audio = "Audio",
  video = "Video",
  shortCall = "15 min. call",
  longCall = "30 min. call",
  free = "Free",
}

export interface MessageLineAttribute {
  isTitle?: boolean;
  isSubtitle?: boolean;
  isSongPreview?: boolean;
  isPrivate?: boolean;
  coverUrl?: string;
  isParagraph?: boolean;
  isLink?: boolean;
  proOnly?: boolean;
}

export interface Product {
  checkoutId?: string;
  _id: string;
  proId: string;
  pro?: User;
  proName?: string;
  type: ProductType;
  price: number; // price in dollars
  isAvailable: boolean;
  isFree: boolean;
}

export interface ProductError {
  writtenError: string;
  audioError: string;
  videoError: string;
  shortCallError: string;
  longCallError: string;
}

export type MappedProduct = {
  [key: string]: Product;
};

export function emptyMappedProduct(proId: string): MappedProduct {
  const product: MappedProduct = {};
  for (const key in ProductType) {
    product[key] = {
      proId,
      type: key,
      price: 0,
      isAvailable: false,
    } as Product;
  }
  return product;
}

export function printableFeedbackOptions(option: string) {
  switch (option) {
    case "production":
      return "Production";
    case "songwriting":
      return "Songwriting";
    case "tracking/recording":
      return "Tracking/Recording";
    case "mixing":
      return "Mixing";
    case "editing":
      return "Editing";
    case "mastering":
      return "Mastering";
    case "other":
      return "Other";
    case "marketing":
      return "Marketing";
    case "playlist-pitching":
      return "Playlist Pitching";
    case "branding":
      return "Branding";
    case "video-marketing":
      return "Video Marketing";
    case "tour-marketing":
      return "Tour Marketing";
    case "release-planning":
      return "Release Planning";
    case "career":
      return "Career";
    case "joining-a-label":
      return "Joining a Label";
    case "finding-a-manager":
      return "Finding a Manager";
    case "booking-touring":
      return "Booking & Touring";
    case "royalties-commissions":
      return "Royalties & Commissions";
  }
}
